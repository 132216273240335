@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@-webkit-keyframes video-slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

@keyframes video-slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes swing {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }
}

@-webkit-keyframes swing {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }
}

@-webkit-keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(1.7) rotateZ(180deg);
    transform: scale(1.7) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1.4) rotateZ(360deg);
    transform: scale(1.4) rotateZ(360deg);
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }

  to {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }

  to {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
}

@keyframes slide-phone {
  0% {
    left: -3px
  }

  5% {
    left: 0
  }

  10% {
    left: 3px
  }

  15% {
    left: 0
  }

  20% {
    left: -3
  }

  25% {
    left: 0
  }

  30% {
    left: 3px
  }

  35% {
    left: 0
  }

  40% {
    left: -3
  }

  45% {
    left: 0
  }

  50% {
    left: 3px
  }

  70% {
    left: 0
  }

  to {
    left: 0
  }
}

@-webkit-keyframes slide-phone {
  0% {
    left: -3px
  }

  5% {
    left: 0
  }

  10% {
    left: 3px
  }

  15% {
    left: 0
  }

  20% {
    left: -3
  }

  25% {
    left: 0
  }

  30% {
    left: 3px
  }

  35% {
    left: 0
  }

  40% {
    left: -3
  }

  45% {
    left: 0
  }

  50% {
    left: 3px
  }

  70% {
    left: 0
  }

  to {
    left: 0
  }
}

@keyframes slide-btt {
  0% {
    bottom: 53px;
  }

  5% {
    bottom: 50px
  }

  10% {
    bottom: 47px
  }

  15% {
    bottom: 50px
  }

  20% {
    bottom: 47px
  }

  25% {
    bottom: 50px
  }

  30% {
    bottom: 53px
  }

  35% {
    bottom: 50px
  }

  40% {
    bottom: 47px
  }

  45% {
    bottom: 50px
  }

  50% {
    bottom: 53px
  }

  70% {
    bottom: 50px
  }

  to {
    bottom: 50px
  }
}

@-webkit-keyframes slide-btt {
  0% {
    bottom: 53px;
  }

  5% {
    bottom: 50px
  }

  10% {
    bottom: 47px
  }

  15% {
    bottom: 50px
  }

  20% {
    bottom: 47px
  }

  25% {
    bottom: 50px
  }

  30% {
    bottom: 53px
  }

  35% {
    bottom: 50px
  }

  40% {
    bottom: 47px
  }

  45% {
    bottom: 50px
  }

  50% {
    bottom: 53px
  }

  70% {
    bottom: 50px
  }

  to {
    bottom: 50px
  }
}