@import url('./styles-custom-responsive.css');

/* css new for Like It's */
/* ------------------------------------------------ */

.container-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  z-index: 26;
}

.container-loading .ant-spin {
  position: fixed;
  top: 50%;
  left: 50%;
}

.ant-back-top {
  z-index: 25 !important;
}

.ant-back-top {
  right: 32px !important;
  height: 48px !important;
  width: 48px !important;
  animation: slide-btt 1s infinite;
}

.btt-custom-img {
  right: 32px !important;
  height: 48px !important;
  width: 48px !important;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px #9212121A;
  text-align: center;
  position: fixed;
  bottom: 50px;
  z-index: 25 !important;
}

.ant-back-top img {
  /* animation: slide-btt 1s infinite; */
  margin-top: 10px;
}

.btn_call {
  position: fixed;
  bottom: 104px;
  right: 8px;
  z-index: 25;
}

.btn_call .btn_call-bgr {
  background-color: #337ab7;
  border-radius: 100%;
  border: 2px solid transparent;

  width: 100px;
  height: 100px;
  opacity: .4;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite;
  transition: all .5s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

.btn_call .btn_call-icon {

  background-color: #337ab7;
  transform: scaleX(-1);
  width: 60px;
  height: 60px;
  top: 20px;
  left: 20px;
  position: absolute;
  transform-origin: 50% 50%;
  display: flex;
  align-items: center;
  border-radius: 100%;
  border: 2px solid transparent;
}

.btn_call .btn_call-icon .btn_call-icon_phone {

  background: transparent url('../../assets/images/new-image/icon-call-1.svg') no-repeat 50%;
  background-size: 50% 50%;
  width: 60px;
  height: 60px;
  position: relative;
  animation: slide-phone 1s infinite ease-in-out !important;
  transform: rotate(260deg);
}



.layout-custom {
  padding-left: calc(50% - 650px) !important;
  padding-right: calc(50% - 650px) !important;
  margin: 100px 20px 0 40px;
  min-height: calc(100vh - 520px);
}

.layout-custom-mobile {
  margin-top: 0px;
  min-height: calc(100vh - 412px);
}

.overlay-mobile {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.45) !important;
  display: none;
}

.header-custom {
  padding: 0 24px 0 28px !important;
  box-shadow: 0px 2px 6px 0px #343a400d;
  height: auto !important;
  line-height: normal !important;
  background-color: #263475 !important;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 16;
}

.icon-menu-mobile,
.header-noti-mobile {
  display: none;
  padding-top: 16px;
  padding-bottom: 16px;
}

.header-search,
.header-icon {
  padding-top: 16px;
  padding-bottom: 16px;
}

.header-search-input {
  width: 625px !important;
  background-color: #18224f !important;
  height: 40px !important;
  border: none !important;
  border-radius: 10px !important;
  position: relative;
}

.header-search-input .ant-select-selector {
  background-color: #18224f !important;
  color: #808191;
  font-size: 12px !important;
  font-weight: 400;
  border: none !important;
  height: 40px !important;
  border-radius: 10px !important;

}

.header-search-input .ant-select-selector .ant-select-selection-search {
  height: 40px !important;
}

.header-search-input .ant-select-selector .ant-select-selection-search input {
  height: 40px !important;
}

.header-search-input .ant-select-selector .ant-select-selection-item {
  line-height: 40px !important;
}

.header-search .header-search-input .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.ant-select-dropdown {
  padding: 16px 0 !important;
  background-color: #18224f !important;
}

.ant-select-dropdown .ant-select-item {
  color: #fff !important;
  font-size: 14px !important;
  font-family: SF Pro Display !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.ant-select-dropdown .ant-select-item:hover {
  background-color: #263475 !important;
}

.header-account-img {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-right: 4px;
}

.header-login {
  margin-right: 16px;
  color: #fff;
  padding: 10px 24px;
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: #e01f65;
  cursor: pointer;
  border-radius: 10px;
}

.header-login:hover {
  background-color: #18224f;
  color: #fff;
}

.header-account-name {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.header-account-btn {
  margin-left: 4px;
  height: 20px;
  width: 20px;
}

.header-noti:hover {
  transform: scale(1.2);
}

.header-noti {
  margin-left: 30px;
}

.ant-dropdown .ant-dropdown-menu {
  background-color: #252836;
  padding: 12px 16px;
  border-radius: 10px;
  width: 240px;
}

.ant-dropdown-menu-item {
  color: #fff !important;
  padding: 12px 0 !important;
  font-family: SF Pro Display !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  /* margin-bottom: 16px !important; */
}

.ant-dropdown-menu-item .dropdown-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.ant-dropdown-menu-item .dropdown-info .dropdown-info_img {
  height: 80px;
  width: 80px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.ant-dropdown-menu-item .dropdown-info .dropdown-info_img:hover {
  border: 3px solid #e01f65;
}

.ant-dropdown-menu-item .dropdown-info .dropdown-info_title {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
}

.ant-dropdown-menu-item .dropdown-info .dropdown-info_subtitle {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #e01f65;
}

.ant-dropdown-menu-item .dropdown-info .dropdown-info_subtitle:hover {
  color: #fff;
}

.ant-dropdown-menu-item .dropdown_logout img {
  margin-right: 8px;
}

.ant-dropdown-menu-item .dropdown_logout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}

.ant-dropdown-menu-item:hover .dropdown-change_pw,
.ant-dropdown-menu-item:hover .dropdown_logout {
  background-color: transparent !important;
  color: #e01f65 !important;
}

.ant-dropdown-menu-item:last-child {
  border-top: 1px solid #ffffff80;
}

.dropdown-change_pw img {
  margin-right: 8px;
}

.footer-container {
  background-color: #0f1842;
}

.footer-custom {
  padding: 86px 0 101px;
  margin-left: 40px;
  margin-right: 20px;

  padding-left: calc(50% - 750px) !important;
  padding-right: calc(50% - 750px) !important;
}

.footer-custom a:hover {
  color: #e01f65 !important;
}

.footer-custom .footer-social .ant-col:hover {
  transform: scale(1.2);
}

.footer-title {
  font-family: 'SF Pro Display';
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 20px;
  color: #fff;
}

.footer-subtitle {
  font-family: 'SF Pro Display';
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  color: #a9acbb;
}

.footer-item {
  padding-bottom: 16px;
}

.footer-address {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #a9acbb;
  width: 60px;
  flex-wrap: nowrap;
}

.footer-sub_address {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #ffffff;
}

.ant-layout-sider-trigger {
  /* display: none; */
}

.ant-layout.ant-layout-has-sider {
  background-color: #263475 !important;
  z-index: 21;
}

.ant-layout-sider-dark {
  background-color: #263475 !important;
  margin-top: 80px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 0 !important;
}

.sidebar-customer-content {
  background-color: #18224f !important;
  border: none !important;
  border-radius: 8px !important;
  margin-left: 16px;
  padding: 20px 12px !important;
  /* width: 234px !important; */
  position: sticky;
  top: 80px;
  z-index: 0;
  width: 100%;
}

.menu-mobile__link>.sidebar-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.menu-mobile__link>.sidebar-label {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #8b90a7;
}

.ant-menu-item-selected .sidebar-label {
  color: #fff !important;
}

.ant-menu-item-selected {
  background-color: #e1116d !important;
  border-radius: 10px;
  border-right-width: 0px;
  height: 48px;
}

.ant-menu-item-selected:hover>.sidebar-label {
  color: #fff !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0 16px !important;
}

/* home */

.header-logo {
  width: 180px;
  text-align: center;
}

.logo-header {
  max-height: 64px;
  margin-left: 20px;
  padding-top: 4px;
  animation: swing 3s linear 3;
}


.handle-click {
  cursor: pointer;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.container-home {}

.carousel-banner>.slick-dots li button {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  bottom: 16px;
}

.banner-img {
  border-radius: 28px;
  width: 100%;
}

.header-logo_custom {
  display: none;
}

.hot-video {
  margin-top: 24px;
  padding: 40px 40px 80px;
  background-color: #18224f;
  border-radius: 10px;
}

.hot-video-home_featured {
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hot-video-head {
  padding-bottom: 40px;
}

.title-custom {
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
}

.subtitle-custom {
  font-family: SF Pro Display;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}

.subtitle-custom:hover,
.life-tip-home-read_more:hover,
.learn-home-read_more:hover,
.podcast-home-read_more:hover {
  color: #e1116d;
}

.hot-video-item {}

.hot-video-item-img {
  position: relative;
}

.hot-video-item-img .hot-video-item-img_thumbnail {
  width: 100%;
  object-fit: cover;
}

.hot-video-item-img .hot-video-item-img_icon-play {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  height: 56px;
  width: 56px;
}

.hot-video-item:hover .hot-video-item-img .hot-video-item-img_thumbnail {
  opacity: 0.4;
}

.hot-video-item:hover .hot-video-item-img .hot-video-item-img_icon-play {
  -webkit-animation: rotate-scale-up 0.8s linear both;
  animation: rotate-scale-up 0.8s linear both;
}

.hot-video-item_title {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;

  padding-bottom: 7px;
}

.hot-video-item_view {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  color: #808191;
  max-height: 90px;
  padding-bottom: 7px;
}

.hot-video-item_description {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffffcc;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  max-height: 60px;
}

.hot-video-img {
  position: relative;
  height: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-size 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 24px;
}

.hot-video-img .hot-video-img_icon-play {
  position: absolute;
  width: 92px;
  height: 92px;
  top: calc(50% - 46px);
  left: calc(50% - 46px);
}


.hot-video-home_featured:hover .hot-video-img_icon-play {
  -webkit-animation: rotate-scale-up 0.8s linear both;
  animation: rotate-scale-up 0.8s linear both;
}

.hot-video-img .hot-video-img_title {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom right, transparent 0%, transparent 10%, transparent 20%, transparent 30%, transparent 40%, transparent 49%, #00d3f0 50%, #00d3f0 100%);
  height: 72px;
}

.hot-video-img .hot-video-img_title span {
  color: #703d99;
  font-size: 34px;
  font-weight: 700;
  margin-right: 16px;
}

.hot-video_title span {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  height: 30px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.hot-video_view {
  color: #808191;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 7px;
  height: 12px;
}

.hot-video_description {
  color: #ffffffcc;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  height: 60px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.podcast-home {
  margin-top: 24px;
  padding: 20px 0 40px 0;
}

.podcast-home-head {
  padding-bottom: 40px;
}

.podcast-home-title {
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
}

.podcast-home-read_more {
  line-height: 24px;
  color: #fff;
}

.podcast-home-img {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.podcast-home-item {
  -webkit-animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.podcast-home-item:hover {
  box-shadow: 0px 16px 16px -10px #18224f;
}

.podcast-home-item_title {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.podcast-home-item_subtitle {
  padding-top: 8px;
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff80;
}

.life-tip-home,
.learn-home,
.game-home {
  margin-top: 24px;
  padding: 20px 0 20px 0;
}

.life-tip-home-head {
  padding-bottom: 40px;
}

.life-tip-home-title {
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
}

.life-tip-home-read_more,
.learn-home-read_more {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #fff;
}

.life-tip-home_item:hover {
  transform: scale(0.9);
  transition-duration: 1s;
}

.life-tip-home-item_img {
  width: 100%;
}

.life-tip-home-item_title {
  padding-top: 16px;
  font-family: SF Pro Display;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.life-tip-home-item_subtitle {
  padding-top: 8px;
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff80;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.life-tip-home-item_subtitle span {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.life-tip-home_featured {
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.life-tip-home-img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-size 0.6s ease;
  transition-duration: 0.6s;
}

.life-tip-home_featured:hover .life-tip-home-img {
  background-size: 140%;
}

.life-tip-home-item-title {
  font-family: SF Pro Display;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
  margin-top: 20px;
  max-height: 72px;
  min-height: 36px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.life-tip-home-subtitle {
  color: #ffffff80;
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
  min-height: 40px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.life-tip-home-subtitle span {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
}

/* profile */

.container-profile {
  padding: 0 20px;
  background-color: #18224f;
  border-radius: 10px;
  margin-bottom: 40px;
}

.profile-category .ant-tabs-tab-btn {
  color: #ffffff80 !important;
}

.profile-category .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.profile-category.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 1px solid #ffffff80 !important;
}

.profile-category.ant-tabs-top .ant-tabs-ink-bar {
  background: #ffffff !important;
}

/* video */
.video-head {
  background-color: #18224f;
  padding-left: 20px;
  padding-top: 24px;
  border-radius: 10px;
}

.video-head_title {
  font-family: SF Pro Display;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}

.video-head_subtitle {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #ffffffcc;
}

.video-category {
  margin-top: 32px !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 32px !important;
}

.profile-category .video-category {
  margin-top: 0 !important;
}

.profile-category .video-category.ant-tabs-card.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 20px !important;
}

.profile-category .video-category .video-category-item {
  margin-top: 0 !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
  margin-right: 16px;
  border-radius: 10px !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  color: #fff !important;
  background: #18224f !important;
  border: 1px solid #18224f !important;
}

.ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
}

.ant-tabs-card .ant-tabs-tab .ant-tabs-tab-btn {
  color: #fff !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none !important;
}

.video-category-head_content {
  position: relative;
}

.video-category-head_content .video-category-head_content-vid {
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.video-category-head_content .video-category-head_content-icon_play {
  position: absolute;
  top: calc(50% - 46px);
  left: calc(50% - 46px);
}

.video-category-head_content .video-category-head_content-icon_play img {
  height: 72px;
  width: 72px;
}

.video-category-head_content:hover .video-category-head_content-icon_play img {
  -webkit-animation: rotate-scale-up 0.8s linear both;
  animation: rotate-scale-up 0.8s linear both;
}

.video-category-head_content .overlay_title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 1;
  transition: opacity 0.8s ease;
}

.video-category-head_content .overlay_title .overlay_title-txt {
  position: relative;
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #fff;
  max-width: 260px;
}

.video-category-head_content .overlay_title .overlay_subtitle-txt {
  position: relative;
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #7e7e7e;
  margin-top: 24px;
  /* max-width: 260px; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.video-category-head_content .overlay_title .overlay_title-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.video-category-head_content .overlay_title .overlay_title-author .overlay_title-author_img {
  height: 60px;
  width: 60px;
  padding: 4px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
}

.video-category-head_content .overlay_title .overlay_title-author .overlay_title-author_img-1 {
  position: absolute;
  height: 16px;
  width: 16px;
  bottom: 0;
  right: 0;
}

.video-category-head_content .overlay_title .overlay_title-author .overlay_title-author_title {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.video-category-head_content .overlay_title .overlay_title-author .overlay_title-author_title .overlay_title-author_name {
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
}

.video-category-head_content .overlay_title .overlay_title-author .overlay_title-author_title .overlay_title-author_time {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  margin-top: 12px;
}

.video-category-head_content .overlay_title .overlay_title_duration {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #242730;
  opacity: 0.7;
  font-family: SF Pro Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffffff;
  padding: 6px 8px;
  border-radius: 8px;
}

/* .video-category-head_content .overlay_title_1 {
  background-image: url('../images/new-image/video-item-first.svg');
  background-size: cover;
  background-repeat: no-repeat;
} */

.video-category-head_content .overlay_title_2 {
  background-color: #f4be3a;
  background-image: none;
}

.video-category-head_content:hover {
  opacity: 1;
}

.video-category-head_content:hover .overlay_title {
  opacity: 1;
}

.video-category-item,
.podcast-item {
  margin-top: 32px;
  margin-bottom: 32px;
}

.video-category-item .video-category-item-animation:hover {
  -webkit-animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.video-category-item_img {
  cursor: pointer;
  height: 160px;
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.video-category-item_duration {
  background-color: #242730;
  opacity: 0.7;
  border-radius: 8px;
  font-family: SF Pro Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 6px 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}

/* .video-category-item_img:hover {
    -webkit-animation: scale-down-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
    animation: scale-down-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
  } */
.video-category-item_content {
  padding: 24px 24px 24px 20px;
  background-color: #18224f;
  border-radius: 0 0 20px 20px;
  position: relative;
}

.video-category-item_content_author {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #b7b9d2;
}


.video-category-item_content-img_author {
  position: absolute;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  padding: 4px;
  right: 20px;
  top: -23px;
  height: 54px;
  width: 54px;
}

.video-category-item_content-img_author img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.video-category-item_content-img_author .video-category-item_content-img-verify {
  position: absolute;
  height: 16px;
  width: 16px;
  bottom: 0;
  right: 0px;
}

.video-category-item_content_title {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #ffffff;
  margin-top: 12px;
  cursor: pointer;
  height: 52px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.video-category-item_content_time {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #808191;
  margin-top: 16px;
  height: 24px;
}

.video-category-pagination {
  text-align: center;
  margin-bottom: 114px !important;
  font-family: SF Pro Display !important;
}

.ant-pagination-item {
  font-family: SF Pro Display !important;
  height: 37px !important;
  background-color: transparent !important;
  border-radius: 12px !important;
  /* border: 1px solid #ffffff80 !important; */
}

.ant-pagination-item-active {
  background-color: #e01f65 !important;
  border: 1px solid #e01f65 !important;
}

.ant-pagination-item:hover {
  color: #fff !important;
  background-color: #e01f65 !important;
  border: none !important;
  border: 1px solid #e01f65 !important;
}

.ant-pagination-item a {
  padding: 8px 16px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #fff !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-item-link {
  color: #fff !important;
  border-color: #ffffff80 !important;
  cursor: not-allowed;
  background-color: transparent !important;
}

.layout-admin-container .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-item-link {
  color: #00000040 !important
}

.container-video-detail_title_mobile {
  display: none;
}

.container-video-detail_title_mobile {
  display: block;
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #f5f8fb;
}

/* about us */
.about-us-title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  margin-bottom: 24px !important;
}

.about-us-banner img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.about-us-content {
  padding: 44px 20px 80px;
  background-color: #18224f;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 0 0 10px 10px;
}

.about-us-card {
  padding-top: 24px;
  padding-bottom: 56px;

}

.about-us-card_item {
  padding: 20px;
  background-color: #18224f;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 10px;
}

.about-us-card_item a {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.about-us-card_item a:hover {
  color: #e1116d;
}

/* video detail */
.container-video-detail {}

.container-video-detail_title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}

.container-video-detail_subtitle {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #ffffff80;
}

.video-detail_content {
  margin-top: 24px;
  margin-bottom: 120px;
}

.video-detail_content .videoPlayer {
  position: relative;
}

.video-detail_content .videoPlayer .videoPlayer_icon_prev,
.video-detail_content .videoPlayer .videoPlayer_icon_next {
  position: absolute;
  opacity: 0.5;
  top: 42%;
  cursor: pointer;
}

.video-detail_content .videoPlayer .videoPlayer_icon_prev {
  left: 0;
}

.video-detail_content .videoPlayer .videoPlayer_icon_next {
  right: 0;
}

.video-detail_content .videoPlayer .videoPlayer_icon_prev:hover,
.video-detail_content .videoPlayer .videoPlayer_icon_next:hover {
  opacity: 1;
}

.video-detail_content_related-label {
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  padding-bottom: 8px;
}

.related_video-item {
  padding-bottom: 12px;
  border-bottom: 1px solid #ffffffc2;
}

.related_video-item:hover .related_video_thumbnail-play {
  display: block;
}

.related_video_thumbnail {
  position: relative;
  cursor: pointer;
  -webkit-animation: slide-fwd-center 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.2s both;
  animation: slide-fwd-center 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.2s both;
}

.related_video_thumbnail-duration {
  position: absolute;
  background-color: #000000;
  color: #fff;
  padding: 3px 4px;
  text-align: center;
  bottom: 4px;
  right: 8px;
  font-family: SF Pro Display;
  font-size: 10px;
  height: 18px;
  line-height: 12px;
}

.related_video_thumbnail-play {
  position: absolute;
  right: 12px;
  top: 4px;
  background-color: #000000b8;
  padding: 2px 4px 0;
  border-radius: 4px;
  display: none;
}

.related_video_thumbnail img {
  width: 100%;
}

.related_video_title {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  padding-bottom: 2px;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.related_video_author {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #aaaaaa;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.related_video_view {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #aaaaaa;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.video-detail_content-img {
  width: 100%;
}

.video-detail_content-title {
  width: 100%;
}

.video-detail_content-title {
  padding-top: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
}

.video-detail_content-view_item {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #aaaaaa;
}

.video-detail_content-action {
  justify-content: end;
}

.video-detail_content-view_action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 36px;
}

.video-detail_content-view_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.video-detail_content-view_txt {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #fff;
  margin-top: 4px;
}

.video-detail_content-line {
  height: 1px;
  border-bottom: 1px solid #303030;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.video-detail_content-description {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #e1e1e6;
}

.video-detail_content-description>span {
  color: #e01f65;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.video-detail_cmt_total-txt {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #fff;
}

.video-detail_cmt_total-sort {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #fff;
  cursor: pointer;
  margin-left: 28px;
}

.video-detail_cmt-input {
  margin-top: 24px;
}

.video-detail_cmt-input img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.video-detail_cmt-input .ant-input {
  border: none;
  background-color: transparent;
  color: #aaaaaa;
  /* border-bottom: 1px solid #303030; */
}

.video-detail_cmt-input .ant-input:hover {
  border-color: #b2b2b2;
}

.video-detail_cmt-input .ant-input:focus {
  border-color: #b2b2b2;
  box-shadow: none;
}

.video-detail_cmt-input .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border: none;
  border-color: #b2b2b2;
}

.video-detail_cmt-input .ant-input-affix-wrapper {
  border: none;
  background-color: transparent;
  color: #aaaaaa;
  border-bottom: 1px solid #303030;
}

.video-detail_cmt-input .ant-input-affix-wrapper .ant-input-suffix {
  filter: invert;
}

.video-detail_cmt-input .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #b2b2b2;
}

.video-detail_cmt-input .ant-input-affix-wrapper:focus {
  border-color: #b2b2b2;
  box-shadow: none;
}

.video-detail_cmt-input_cancel {
  margin: 8px 16px;
  justify-content: end;
}

.video-detail_cmt-input_cancel span {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #fff !important;
  padding: 8px;
  margin-right: 8px;
  margin-top: 4px;
}

.video-detail_cmt-input_cancel span:hover {
  padding: 8px;
  border-radius: 16px;
  background-color: #18224f;
  color: #fff;
  text-align: center;
}

.video-detail_cmt_total-sort img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.video-detail_cmt {
  margin-top: 16px;
}

.video-detail_show_cmt {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #dfdfdf;
  display: inline-flex;
  padding: 8px;
  margin-bottom: 8px;
}

.video-detail_show_cmt {
  color: #dfdfdf;
}

.video-detail_show_cmt:hover {
  color: #e01f65;
  padding: 8px;
  border-radius: 16px;
  background-color: #fafafa;
}

.video-detail_show_cmt:hover span {
  color: #e01f65;
}

.video-detail_cmt-item {
  flex-wrap: nowrap !important;
  margin-top: 8px;
}

.video-detail_cmt-item .delete-cmt-parent,
.video-detail_cmt-item .video-detail-user-cmt_action .video-detail_cmt-item .delete-cmt {
  display: none;
}

.video-detail_cmt-item:hover .delete-cmt-parent,
.video-detail_cmt-item .video-detail-user-cmt_action .video-detail_cmt-item:hover .delete-cmt {
  display: block;
}

.video-detail_cmt_img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.video-detail_cmt_img-custom {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.video-detail-user-cmt_name {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: #ffff !important;
}

.video-detail-user-cmt_date {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #aaaaaa;
}

.video-detail-user-cmt_content {
  font-family: SF Pro Display;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}

.video-detail-user-cmt_action,
.video-detail-user-cmt_action span {
  font-family: SF Pro Display;
  line-height: 18px;
  color: #aaaaaa;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

/* podcast */
.container-podcast .video-category {
  margin-top: 0 !important;
}

.container-podcast .video-category .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.container-podcast .ant-tabs .ant-tabs-nav .ant-tabs-nav-operations .ant-tabs-nav-more {
  display: none;
}

.podcast-category-item {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 26px;
  grid-row-gap: 24px;
}

.podcast-category-item .podcast-banner-img {
  width: 40%;
  max-width: 460px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

.podcast-category-item .podcast-banner-img img {
  display: none;
  width: 100%;
}

.podcast-category-item .podcast-category-item_content {
  display: flex;
  flex-direction: column;
  background: #18224f;
  padding: 20px 21px 23px;
  border-radius: 10px;
  width: 18%;
}

.podcast-category-item .podcast-category-item_content .podcast-category-img {
  position: relative;
}

.podcast-category-item .podcast-category-item_content .podcast-category-img .podcast-category-img_thumbnail {
  width: 100% !important;
  height: 175px;
  cursor: pointer;
  object-fit: cover;
  object-position: right;
}

.podcast-category-item .podcast-category-item_content .podcast-category-img .podcast-banner-icon_play {
  position: absolute;
  height: 62px;
  width: 62px;
  bottom: 5px;
  right: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 16px 0px #00000073;
  cursor: pointer;
  opacity: 0;
  transform: opacity 0.6s ease;
  transition-duration: 0.6s;
}

.podcast-category-item .podcast-category-item_content .podcast-category-content-txt {
  margin-top: 25px;
}

.podcast-category-item .podcast-category-item_content .podcast-category-content-txt .content_title {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.87px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #fff;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.podcast-category-item .podcast-category-item_content .podcast-category-content-txt .content_subtitle {
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  text-align: left;
  color: #b3b3b3;
  height: 42px;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.podcast-category-item .podcast-category-item_content:hover {
  animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.podcast-category-item .podcast-category-item_content:hover .podcast-category-img .podcast-banner-icon_play {
  opacity: 1;
}

/* life tips */
.life-tip-content_img {
  position: relative;
}

.life-tip-content_img img {
  width: 100%;
  border-radius: 10px;
  min-height: 180px;
}

.life-tip-content_img .life-tip-content_img-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 16px;
  left: 28px;
  right: 28px;
}

.life-tip-content_img .life-tip-content_img-label .life-tip-content_img-title {
  font-family: SF Pro Display;
  font-size: 22px;
  font-weight: 400;
  line-height: 23.87px;
  text-align: left;
  color: #fdfdfd;
}

.life-tip-content_img .life-tip-content_img-label .life-tip-content_img-subtitle {
  margin-top: 16px;
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  text-align: left;
  color: #fdfdfd;
}

.container-life-tip {
  margin-bottom: 64px;
}

.container-life-tip .carousel-banner {
  margin-bottom: 40px;
}

.life-tip-related {
  background-color: #18224f;
  border-radius: 10px;
  padding: 24px;
}

.life-tip_trend_title {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}

.life-tip_trend_read-more {
  background: #e01f65;
  border-radius: 10px;
  padding: 8px 24px;
  font-family: SF Pro Display;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  color: #fff;
  cursor: pointer;
}

.life-tip_trend_item {
  padding: 24px 0 0;
}

.life-tip_trend_item img {
  width: 100%;
  /* max-width: 140px; */
}

.life-tip_trend_item .life-tip_trend_item-content {
  padding-left: 8px;
}

.life-tip_trend_item .life-tip_trend_item-content .life-tip_trend_item-content_flex {
  /* flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.life-tip_trend_item .life-tip_trend_item-content .life-tip_trend_item-content-label {
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.life-tip_trend_item .life-tip_trend_item-content .life-tip_trend_item-content-sublabel {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: #ffffff80;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  padding-top: 10px;
}

.life-tip_trend_item .life-tip_trend_item-content .life-tip_trend_item-content-author {
  font-family: SF Pro Display;
  font-size: 9px;
  font-weight: 600;
  line-height: 9px;
  color: #ffffff80;
  padding-top: 18px;
}

.life-tip_trend_item .life-tip_trend_item-line {
  margin: 24px 0;
  width: 100%;
  border: 1px solid #ffffff80;
  border-radius: 2px;
}

.container-life-tip .video-category-pagination {
  margin-top: 40px;
}

/* learning */
.container-learn {
  margin-top: 40px;
  margin-bottom: 120px;
}


.container-learn .learn-head img {
  border-radius: 10px;
}

.container-learn .learn-head {
  background-color: #8950fc;
  padding-left: 24px;
  border-radius: 10px;
  margin-bottom: 32px;
}

.container-learn .learn-head .learn-head_title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
}

.container-learn .learn-head .learn-head_subtitle {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: #ffffff99;
}


.container-learn .learn-content .learn-content_title {
  margin-bottom: 20px;
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}

.container-learn .learn-item {
  background-color: #18224f;
  padding: 16px;
  border-radius: 24px;
}

.container-learn .learn-item:hover {
  -webkit-animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.container-learn .learn-item .learn-item-img {
  width: 100%;
  border-radius: 24px;
  cursor: pointer;
}

.container-learn .learn-item .learn-item-title {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
  margin-top: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.container-learn .learn-item .learn-item-title-custom {
  font-size: 22px;
  line-height: 23px;
  margin-top: 16px;
}

.container-learn .learn-item .learn-item-author {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #e01f65;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.learn-item-author-custom {
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;

}

.container-learn .learn-item .learn-item-description {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff80;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;

  margin-bottom: 4px;
}

.learn-item-description-custom {
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;


}

/* game */
.game-title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
  padding-bottom: 24px;
}


/* learn detail */
.learn-detail_content-title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 24px;
}

.learn-detail_content-view {
  margin-top: 24px;
}

.learn-detail_content-img_acc {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  cursor: pointer;
}

.learn-detail_content-title_acc {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-detail_content-subtitle_acc {
  font-family: SF Pro Display;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #ffffff80;
}

.learn-detail_content_view-img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.learn-detail_content_view-title {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-detail_content-line {
  height: 1px;
  border-bottom: 1px solid #1b1b1b0f;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.learn-detail_content-description-head {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ffffff;
  padding-bottom: 16px;
}

.learn-detail_content-description {
  color: #ffffff80;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.learn-detail_review-title {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ffffff;
  margin-top: 32px;
}

.learn-detail_review {
  margin-top: 16px;
}

.learn-detail_review_img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.learn-detail_review_name {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-detail_review_cmt {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff80;
  padding-top: 19px;
}

.learn-detail_category-test {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  border: 1px solid #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 18px;
  cursor: pointer;
}

.learn-detail_category-related {
  position: relative;
}

.learn-detail_category-related img {
  width: 100%;
}

.learn-detail_category-related_txt {
  position: absolute;
  bottom: 40px;
  left: 20px;
  right: 22px;
}

.category-related_txt-live {
  background-color: #e01f65;
  padding: 4px;
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 2px;
}

.category-related_txt-author {
  padding-top: 4px;
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #f9f9f9e5;
}

.category-related_txt-title {
  padding-top: 4px;
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-detail_category-history {
  background-color: #f4f4f4;
  border-radius: 10px;
  /* padding: 8px 12px; */
}

.learn-detail_category-history_chapter {
  padding: 8px 12px 10px;
}

.learn-detail_category-history_img img {
  height: 24px;
  width: 24px;
}

.learn-detail_category-history_chapter-title {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: #1b1b1b;
}

.learn-detail_category-history_chapter-subtitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #1b1b1b99;
}

.learn-detail_category-history_chapter-subtitle span {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;

  color: #e01f65;
}

.learn-detail_category-history_chapter_item {
  padding: 8px 12px;
  background-color: #fff;
}

.learn-detail_category-history_chapter_item-title {
  font-family: SF Pro Display;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;

  color: #1b1b1b;
}

.learn-detail_category-history_chapter_item-subtitle {
  font-family: SF Pro Display;
  font-size: 8px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  color: #00ddc0;
}

.learn-detail_category-history_chapter_item-img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.learn-detail_category-history_chapter_item_pass {
  padding: 4px;
  border-radius: 2px;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 8px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
}

.learn-detail_category-history_chapter_item_pass_bgr-pass {
  background-color: #00ddc0;
}

.learn-detail_category-history_chapter_item_pass_color-pass {
  color: #00ddc0;
}

.learn-detail_category-history_chapter_item_pass_brg-active {
  background-color: #fe416d;
}

.learn-detail_category-history_chapter_item_pass_color-active {
  color: #fe416d;
}

.learn-detail_category-history_chapter_item_pass_color-future {
  color: #1b1b1b1a;
}

.learn-check-result {
  border-radius: 4px;
  margin-top: 32px;
  background-color: #e1116d;
  padding: 10px;
  cursor: pointer;
}

.learn-check-result span {
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-check-result img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.learn-test .question-number {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-test .question-title {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-test .learn-test-question {
  margin-top: 32px;
}

.learn-test .learn-test-answer {
  margin-top: 10px;
}

.learn-test .learn-test-answer .ant-radio-wrapper span {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #fff;
}

.learn-result {
  margin-top: 40px;
  margin-bottom: 40px;
}

.learn-result img {
  width: 100%;
}

/* podcast detail */
.container-podcast-detail {
  background-image: url('../../assets/images/new-image/podcast-detail-bgr.svg');
  background-size: cover;
  margin-bottom: 120px;
}

.podcast-detail {
  padding: 16px 20px 48px;
}

.podcast-detail-prev {
  height: 40px;
  width: 40px;
  margin: 10px 0;
  margin-right: 20px;
  cursor: pointer;
}

.podcast-detail-next {
  height: 40px;
  width: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.podcast-detail-name {
  margin-top: 24px;
}

.podcast-detail-name_img {
  width: 200px;
  height: 200px;
  margin-right: 32px;
  border-radius: 4px;
}

.podcast-detail-name_title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  margin: 12px 0;
  color: #fff;
}

.podcast-detail-name_author {
  font-family: SF Pro Display;
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.06em;
  color: #fff;
  margin-top: 12px;
}

.podcast-detail-name_key {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #fff;
}

.podcast-detail-content {
  margin-top: 56px;
}

.podcast-detail-content_date {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #fff;
}

.podcast-detail-content_active {
  margin-top: 24px;
}

.podcast-detail-content_description {
  margin-top: 24px;
}

.podcast-detail-content_active_play:hover {
  border-radius: 50%;
  border: 1px solid #eee;
  padding: 1px;
}

.podcast-detail-content_active_play {
  /* height: 62px;
    width: 62px; */
  margin-right: 22px;
  cursor: pointer;
}

.podcast-detail-content_active_down {
  height: 52px;
  width: 52px;
  margin-right: 22px;
  cursor: pointer;
}

.podcast-detail-content_active_more {
  height: 44px;
  width: 44px;
  cursor: pointer;
}

.podcast-detail-content_active audio {
  display: block;
}

.podcast-detail-content_description-title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #fff;
}

.podcast-detail-content_description-content {
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #e1e1e6;
  margin-top: 12px;
}

.podcast-detail-content_description-more {
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05em;
  cursor: pointer;
  color: #e01f65;
}

/* life-tip-detail */
.life-tip-detail-banner img {
  width: 100%;
  object-fit: cover;
}

.life-tip-detail-content {
  background-color: #18224f;
  padding: 20px 176px 24px;
  margin-bottom: 54px;
}

.life-tip-detail-content_head {
  font-family: SF Pro Display;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fff;
}

.life-tip-detail-content_info {
  margin-top: 16px;
  border-bottom: 2px solid #f1f0f0;
}

.life-tip-detail-content_info-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.life-tip-detail-content_info-author {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  color: #ffffff80;
}

.life-tip-detail-content_time {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  color: #ffffff80;
}

.life-tip-detail-content_read {
  font-family: SF Pro Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  color: #ffffff80;
}

.life-tip-detail-content_action-cmt {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}

.life-tip-detail-content_action-share {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}

.life-tip-detail-content_action-save {
  height: 32px;
  width: 32px;
}

.life-tip-detail-content_txt {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 24px;
  padding-top: 42px;
  border-bottom: 2px solid #f1f0f0;
  margin-bottom: 16px;
  padding-bottom: 48px;
  line-height: 30px;
  letter-spacing: 0.05rem;
}

.life-tip-detail-content_txt ul {
  padding-top: 16px;
}

.life-tip-detail-content_txt ul li {
  padding-top: 8px;
  list-style: disc;
  margin-left: 32px;
}

.life-tip-detail-content_txt img {
  width: 100%;
  margin: 24px 0 32px;
}

.container-life-tip-detail_title_mobile {
  display: none;
}

/* pingpong detail */
.pingpong-detail {
  padding: 24px 80px 24px 144px;
  border-bottom: 1px solid #fff;
}

.pingpong-detail-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: #fff;
}

.pingpong-detail-time {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  color: #fff;
}

.pingpong-detail-time span {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #ffffff80;
}

.pingpong-detail-img {
  margin-top: 24px;
}

.pingpong-detail-cmt {
  padding: 24px 88px 24px 144px;
  margin-bottom: 72px;
}

.pingpong-detail-cmt_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #fff;
}

/* login */
.container-login {
  box-shadow: 0px 0px 15px 0px #2632380d;
  background: #18224f;
  padding: 24px 19px 24px 56px;
  border-radius: 20px;
  margin-bottom: 48px;
}

.form-login-img img {
  width: 100%;
}

.form-login_sign_content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.form-login_sign_title {
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 500;
  line-height: 41.6px;
  text-align: left;
  color: #e01f65;
}

.form-login_sign_subtitle {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
  margin-top: 8.5px;
}

.form-sign__form {
  width: calc(100% - 18px);
}

.form-login-group {
  margin-top: 20px;
}

.form-login-group_label {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5px;
}

.form-login-group_label-custom {
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 12px;
}

.form-login-group_input {
  background-color: #1f1d2b !important;
  padding: 15.25px 19px 15.25px 20px !important;
  border-radius: 10px !important;
  background: #1f1d2b !important;
  font-family: SF Pro Display !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.6px !important;
  text-align: left !important;
  color: #a0aec0 !important;
  border: 1px solid #1f1d2b !important;
}

.form-login-group .ant-input:hover {
  border-color: #263475 !important;
}

.form-login-group .ant-input:focus {
  border-color: #18224f !important;
}

.form-login-group .eye-icon-input {
  color: #e01f65;
}

.form-login-group .form-sign__line {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.form-login-group .ant-switch-checked {
  background-color: #e01f65 !important;
}

.form-login-group .checkbox_label {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-left: 4px;
}

.form-sign__pass {
  color: #ffffff !important;
}

.form-sign__pass:hover {
  color: #fff;
}

.form-login__btn,
.form-sign__social {
  width: 100%;
}

.form-login__btn button {
  width: 100%;
  background-color: #e01f65;
  padding: 12px;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #fff;
}

.form-login__btn button:hover {
  background: #263475;
}

.form-sign__social {
  width: calc(100% - 18px);
}

.form-sign__social .S9gUrf-YoZ4jf iframe {
  /* margin: 0 !important; */
  width: 100% !important;
}

.form-sign__social .S9gUrf-YoZ4jf iframe .haAclf {
  width: 100% !important;
}

.form-sign__social .S9gUrf-YoZ4jf iframe #container {
  padding: 0 !important;
  width: 100% !important;
}

.form-sign__social .S9gUrf-YoZ4jf iframe .haAclf .nsm7Bb-HzV7m-LgbsSe {
  height: 46px !important;
}

.form-login-register {
  margin-top: 20px;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
}

.form-login-register span {
  color: #e01f65;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
}

.form-login-register span:hover {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.form-login_forget {
  padding: 24px;
  text-align: center;
  background-color: #18224f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-login_forget .form-sign {
  background-color: #18224f;
}

.form-login_forget .form-login_sign_title {
  color: #fff;
}

.form-login_forget label {
  color: #fff;
  margin-bottom: 24px;
}

.form-login_forget_container {
  width: 500px;
}

.form-login_reset_container {
  width: 400px;
}

.form-login__btn .btn-register-login {
  margin-top: 16px;
}

.form-login__btn .btn-register-login a {
  color: #fff;
}

.layout-admin-container {
  padding-left: calc(50% - 650px) !important;
  padding-right: calc(50% - 650px) !important;
  margin: 0px 20px 0 40px;
  min-height: calc(100vh - 520px);
}

.layout-background-color {
  background-color: #263475 !important;
}

.sidebar-admin-mobile .sidebar-admin-content .ant-menu-item-selected {
  background-color: #e6f7ff !important;
}

/* loadding trước khi render hết web */
.loading-before {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#loader-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Đảm bảo loadding overlay nằm trên tất cả các phần tử khác */
}

#loader {
  /* animation: spin 2s linear infinite;
  margin-bottom: 48px; */
}

.rotate180 {
  transform: rotate(180deg);
}

/* // learning -home */

.learn-home {
  padding-bottom: 32px;
  margin-bottom: 0;
}

.learn-home-title {
  font-family: SF Pro Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
}

.learn-home-read_more {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
}

.learn-home-item {
  padding: 16px;
  background-color: #18224f;
}

.learn-home-item .learn-home-item_img {
  width: 100%;
}

/* // profile */

.video-category-item_content-profile {
  background-color: #263475;
}

.sidebar-profile {
  margin-left: 16px;
  height: calc(100vh - 100px);
}

.sidebar-profile .sidebar-profile-content {
  flex-direction: column;
  background-color: #18224f;
  height: 100%;
  padding: 60px 16px;
  border-radius: 10px;
}

.sidebar-profile .sidebar-profile-content .sidebar-profile_img img {
  height: 80px;
  width: 80px;
}

.sidebar-profile .sidebar-profile-content .profile-name {
  margin-top: 16px;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-top: 10px;
  overflow-wrap: break-word;
}

.sidebar-profile .sidebar-profile-content .profile-email {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff80;
  margin-top: 8px;
  overflow-wrap: break-word;
}

.sidebar-profile .sidebar-profile-content .profile-phone {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #ffffff80;
  margin-top: 8px;
  overflow-wrap: break-word;
}

.sidebar-profile .sidebar-profile-content .sidebar-profile-content_line {
  border: 1px solid #d9d9d9;
  width: 100%;
  margin: 24px 0;
}

.sidebar-profile .sidebar-profile-content .profile-contact {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.sidebar-profile .sidebar-profile-content .profile-contact-item {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.sidebar-profile .sidebar-profile-content .profile-contact-item .ant-row-no-wrap {
  margin-top: 12px;
}

.sidebar-profile .sidebar-profile-content .profile-contact-item .profile-contact-item_img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

/* // custom dropdown đa ngôn ngữ */

.dropdown-custom {
  position: relative;
  display: inline-block;
}

.dropdown-toggle-custom {
  border: none;
  cursor: pointer;
}

.dropdown-menu-custom {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-menu-custom a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.dropdown-menu-custom a:hover {
  background-color: #f0f0f0;
}

.dropdown-custom:hover .dropdown-menu-custom {
  display: block;
  width: 120px;
  right: 0;
  padding-top: 16px;
  background-color: transparent;
}

.dropdown-custom:hover .dropdown-menu-custom .dropdown-menu-custom_hover {
  border-radius: 10px;
  background-color: #252836;
}

.dropdown-custom:hover .dropdown-menu-custom .dropdown-menu-custom_hover .dropdown-menu-custom-item {
  margin-top: 4px;
  padding: 10px 12px;
  color: #fff;
  line-height: 12px;
  text-align: center;

  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-custom:hover .dropdown-menu-custom .dropdown-menu-custom_hover .dropdown-menu-custom-item:hover {
  background-color: #757575;
  border-radius: 10px;
  cursor: pointer;
}

.dropdown-custom:hover .dropdown-menu-custom .dropdown-menu-custom_hover .menu-language-active {
  background-color: #757575;
  border-radius: 10px;
}

.dropdown-custom:focus-within .dropdown-menu-custom {
  display: block;
}

.download_title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

/* css menu mobile */
.hideOnMobile {
  display: none;
}

.menu-info-mobile {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.menu-info-mobile .menu-info-mobile_icon-drawer {
  height: 44px;
  width: 44px;
}

.menu-info-mobile .menu-info-mobile_img-avt {
  height: 44px;
  width: 44px;
  margin-right: 16px;
}

.menu-info-mobile_mail {
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 500;
  line-height: 18.7px;
  text-align: left;
  color: #F5F8FB;
  overflow-wrap: break-word;
  word-break: break-all;
}

.menu-info-mobile_name {
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 400;
  line-height: 14.3px;
  text-align: left;
  color: #8F959E;
  padding-top: 16px;
}

@media only screen and (min-width: 992px) {
  .hideOnMobile {
    display: block;
  }
}

.hideOnDesktop {
  display: block;
}

@media only screen and (min-width: 992px) {
  .hideOnDesktop {
    display: none;
  }
}

/* game-home */
.learn-home,
.game-home {
  margin-top: 24px;
  padding: 20px 0 20px 0;
}

.game-home {
  margin-bottom: 32px;
}

.game-home .game-home-content {
  padding-bottom: 20px;
}

.game-home .game-content_title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}

.game-home .game-home-read_more {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
}

.game-home .game-item .game-item-img {
  width: 100%;
}

.game-home .game-item {
  background-color: #18224F;
  padding: 16px;
  border-radius: 10px;
}

.game-home .game-item:hover {
  animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.game-home .game-item .game-item-title {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.87px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #fff;
  margin-top: 16px;
}

.game-home .game-item .game-item-subtitle {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #FFFFFF80;
  margin-top: 12px;
}

.game-home .game-item .game-item-play {
  background-color: #E01F65;
  padding: 12px 10px;
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.29px;
  text-align: left;
  color: #fff;
  border-radius: 4px;
  margin-top: 16px;

  display: flex;
  align-items: center;
}

.game-home .game-item .game-item-play:hover {
  background-color: #263475;
}

.game-home .game-item .game-item-play img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.game-home .game-item .game-line {
  margin: 16px 0;
  border: 1px solid #FFFFFF80;
}

.game-home .game-item .game-item-playing {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: left;
  color: #FFFFFF80;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-home .game-item .game-item-playing img {
  height: 40px;
  width: auto;
}

/* pingpong-home  */

.pingpong-home {
  margin-top: 24px;
  padding: 20px 24px;
  margin-bottom: 120px;
  background-color: #18224F;
  border-radius: 10px;
}

.pingpong-home .pingpong-content {
  padding-bottom: 24px;
}

.pingpong-home .pingpong-content_title {
  font-family: SF Pro Display;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}

.pingpong-home .pingpong-home-read_more {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
}

.pingpong-home .pingpong-item .pingpong-item-img {
  width: 100%;
  min-height: 151px;
}

.pingpong-home .pingpong-item {
  border-radius: 10px;
}

.pingpong-home .pingpong-item:hover {
  animation: video-slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.pingpong-home .pingpong-item .pingpong-item-title {
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  margin-top: 20px;
  color: #fff;
}

.pingpong-item-time {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #808191;
  margin-top: 12px;
}

.pingpong-home .pingpong-item .pingpong-item-subtitle {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  margin-top: 12px;
  color: #fff;
}

.container-game {
  margin-bottom: 120px;
}

.container-game img {
  width: 100%;
  cursor: pointer;
}

/* empty */
.empty-podcast {
  margin-bottom: 48px;
}

.empty .ant-empty-description {
  color: #fff;
}

/* loading */
.loading-container {
  background: #ffffff66;
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* pingpong */
.pingpong-trendy-item {
  padding: 24px;
  border-radius: 10px;
  background-color: #18224f;
  margin-bottom: 24px;
}

.pingpong-trendy-item-list {
  margin-bottom: 20px;
  margin-top: 0px;
}

.pingpong-trendy-item_title {
  font-family: SF Pro Display;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
  padding-bottom: 24px;
}

.pingpong-trendy-item_subtitle {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
}

.pingpong-recently {
  margin-top: 24px;
  margin-bottom: 64px;
}

.pingpong-recently .pingpong-recently-item {
  background-color: #18224F;
  box-shadow: 0px 2px 2px 0px #0000003D;
  box-shadow: 0px 0px 2px 0px #0000001F;
  border-radius: 10px;
}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_img img {
  width: 100%;
}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_title {
  padding: 16px 16px 0;
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.63px;
  text-align: left;
  color: #FFFFFF80;


  /* overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical; */
}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_author {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.47px;
  text-align: left;
  color: #FFFFFF80;
  padding: 8px 16px 0;

}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_action {
  padding: 8px 16px;
}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_action .pingpong-recently-item_action_value {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #FFFFFF80;
}

.pingpong-recently .pingpong-recently-item .pingpong-recently-item_action .pingpong-recently-item_action_cmt {
  margin-right: 8px;
}


.container-pingpong .video-category-pagination {
  margin-top: 24px;
}

.back-to-home {
  font-size: 28px;
  line-height: 28px;
  font-family: SF Pro Display;
  font-weight: 500;
  color: #fff;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.back-to-home img {
  height: 24px;
  width: 24px;
}

.back-to-home:hover {
  color: #e01f65;
}

/* custom dropdown sort màn vid */

.sort_item:hover:hover,
.sort_item-active {
  color: #e01f65;
}

/* css label search */
.ant-select-dropdown .ant-select-item.ant-select-item-group {
  color: #FFFFFF80 !important;
}

/* css background */
.overlay-next_video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-next_video-container .overlay-next_video {
  max-width: 240px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-countdown {
  color: #9c9c9c;
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-countdown span {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-info .overlay-next_video-info_img {
  position: relative;
  margin-bottom: 12px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-info .overlay-next_video-info_img img {
  max-width: 240px;
  border-radius: 12px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video-info .overlay-next_video-info_title {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video_btn {
  padding: 8px;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 16px;
  text-align: center;
  min-width: 80px;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video_btn.overlay-next_video_cancel {
  background-color: #79797988;
}

.overlay-next_video-container .overlay-next_video .overlay-next_video_btn.overlay-next_video_submit {
  background-color: #a1a1a1ad;
}

.overlay-next_video-info_img-time {
  position: absolute;
  right: 2px;
  bottom: 2px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 10px;
}

/* css admin */
.admin-wrapper-setWidth .layout-custom-mobile {
  margin-left: 48px;
}