/* css responsive new for Like It's */
/* ------------------------------------------------ */

@media (min-width: 993px) {
  /* icon khi respon menu mặc định của antd */
  /* .ant-layout-sider-zero-width-trigger {
    display: none !important;
  } */

  .ant-layout-sider {
    position: sticky !important;
    top: 100px !important;
    z-index: 15 !important;
  }

  .ant-menu {
    margin-left: 16px !important;
  }

  .ant-layout-header {}

  .hot-video-item_title {
    font-size: 16px !important;
    -webkit-line-clamp: 1 !important;
  }

  .hot-video-item_description {
    -webkit-line-clamp: 2 !important;
  }

  .hot-video-item_title {
    font-size: 16px !important;
    -webkit-line-clamp: 1 !important;
  }


  .podcast-category-item_content {
    width: 28% !important;
  }
}

@media (min-width: 1200px) {
  .hot-video-item_title {
    font-size: 20px !important;
    -webkit-line-clamp: 3 !important;
  }

  .hot-video-item_description {
    -webkit-line-clamp: 3 !important;
  }

  .podcast-category-item_content {
    width: 22% !important;
  }
}

@media (min-width: 1400px) {
  .podcast-category-item_content {
    width: 18% !important;
  }
}

@media (max-width: 1024px) {

  .header-search,
  .header-icon {
    display: none !important;
  }
}

@media (max-width: 992px) {

  /* for admin */


  /*end for admin */


  /* icon khi respon menu mặc định của antd */
  /* .ant-layout-sider-zero-width-trigger {
    display: none !important;
  } */
  /* end icon */

  .ant-layout-sider-dark {
    margin-top: 0 !important;
  }

  .icon-menu-mobile,
  .header-noti-mobile {
    display: block !important;
  }

  .logo-header {
    margin-left: 0 !important;
  }

  .ant-row {
    margin: 0 !important;
  }

  .footer-custom {
    padding: 40px 20px !important;
    background-color: #0f1842 !important;
  }

  .video-detail_content-video {
    padding: 16px 0 !important;
  }

  .video-detail_content {
    margin-top: 0 !important;
  }

  .video-detail_content-line {
    border-bottom: 1px solid #cecece !important;
  }

  .related_video_thumbnail img {
    border-radius: 10px 10px 0 0 !important;
  }

  .header-logo_custom {
    display: block !important;
  }

  .header-custom {
    z-index: 18 !important;
  }

  .ant-layout-sider-zero-width-trigger {
    top: -56px !important;
    left: 16px !important;
    border-radius: 50% !important;
    background-color: #222e34 !important;
    z-index: 21 !important;
  }

  .ant-layout-sider-zero-width-trigger:hover::after {
    border-radius: 50% !important;
  }

  .ant-menu,
  .sidebar-customer-content {
    margin-left: 0 !important;
  }

  .header {
    background-color: #1f1d2b !important;
  }

  .ant-layout-sider {
    /* position: fixed !important;
    z-index: 21 !important; */
    border-radius: 10px;
  }

  .container-login {
    background: #252836 !important;
    padding: 12px 0 !important;
    margin-top: 80px !important;
    padding: 12px 16px !important;
    border-radius: 0 !important;
  }

  .form-login-img {
    padding: 0 !important;
    margin-bottom: 24px !important;
  }

  .form-login-img img {
    width: 100% !important;
    max-height: 348px !important;
    object-fit: cover !important;
    border-radius: 16px !important;
    object-position: bottom !important;
  }

  .form-login_sign_content {
    padding: 12px 16px !important;
    margin-bottom: 64px !important;
  }

  .container-home,
  .container-video,
  .container-video-detail,
  .container-life-tip,
  .container-life-tip-detail,
  .container-podcast,
  .container-podcast-detail,
  .container-learn,
  .container-learn-detail,
  .container-profile,
  .about-us,
  .container-game,
  .container-pingpong {
    padding: 16px !important;
    margin-top: 80px !important;
    min-height: calc(100vh - 500px);
  }

  .pingpong-trendy-item {
    border-radius: 10px !important;
    background-color: transparent !important;
    padding: 0 !important;
  }

  .pingpong-trendy-item .pingpong-trendy-item-list {
    padding: 0 !important;
  }

  .pingpong-trendy-item .pingpong-trendy-item-list .pingpong-item {
    background-color: #18224F !important;
    padding: 16px;
  }

  .pingpong-detail {
    padding: 16px !important;
  }

  .pingpong-detail-img img {
    width: 100% !important;
  }

  .pingpong-detail-cmt {
    padding: 16px !important;
    margin-bottom: 72px !important;
  }

  .container-life-tip-detail_title_mobile {
    display: block !important;
    padding: 16px 0 !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #f5f8fb !important;
  }

  .learn-head {
    display: none !important;
  }

  .podcast-category-item_content {
    width: 26% !important;
  }

  .podcast-banner-img {
    width: 60% !important;
    max-width: none !important;
  }

  .podcast-detail-content {
    margin-top: 56px;
  }

  .learn-home {
    padding: 16px 0 !important;
  }

  .video-detail_cmt-item {
    margin-bottom: 8px !important;
  }

  .podcast-item,
  .video-category-item {
    margin-top: 32px !important;
    margin-bottom: 32px;
  }
}

@media (max-width: 768px) {
  .banner-img {
    border-radius: 20px !important;
    width: 100% !important;
    height: 540px !important;

    object-fit: cover;
    object-position: left;
  }

  .hot-video-head {
    padding-bottom: 16px !important;
  }

  .hot-video {
    margin-top: 24px !important;
    padding: 0 !important;
    background-color: #263475 !important;
    border-radius: 10px !important;
  }

  .title-custom {
    font-family: SF Pro Display !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    color: #fff !important;
  }

  .subtitle-custom {
    font-family: SF Pro Display !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    color: #fff !important;
  }

  .hot-video-item-img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px 10px 0 0 !important;
  }

  .hot-video-item_content {
    background-color: #242730 !important;
    padding: 16px !important;
    border-radius: 0 10px 10px 0 !important;
  }

  .podcast-home {
    padding-bottom: 0 !important;
  }

  .podcast-home-title {
    font-family: SF Pro Display !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    color: #ffffff !important;
  }

  .podcast-home-read_more {
    font-family: SF Pro Display !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    color: #ffffff !important;
  }

  .podcast-home-item {
    background-color: #252836 !important;
    padding: 16px 10px !important;
    border-radius: 10px !important;
  }

  .podcast-category-img .podcast-banner-icon_play {
    width: 62px !important;
  }

  .pingpong-content_title,
  .game-content_title,
  .learn-content_title,
  .life-tip-home-title {
    font-family: SF Pro Display !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    color: #ffffff !important;
  }

  .life-tip-home-read_more {
    font-family: SF Pro Display !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    color: #ffffff !important;
  }

  .life-tip-home_item {
    background-color: #252836 !important;
    padding: 16px !important;
    border-radius: 10px !important;
  }

  .life-tip-home-item_img {
    border-radius: 24px !important;
    border-radius: 10px !important;
  }

  .life-tip-home-item_title {
    font-family: SF Pro Display !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    color: #ffffff !important;
  }

  .life-tip-home-item_subtitle {
    font-family: SF Pro Display !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    color: #ffffff80 !important;
  }

  .video-head {
    background-color: #252836 !important;
    padding-top: 10px !important;
  }

  .video-head_img {
    text-align: right !important;
  }

  .video-category .video-category-head {
    justify-content: center !important;
  }

  .video-category .video-category-head img {
    width: 100% !important;
    margin-bottom: 16px !important;
  }

  .video-category .video-category-head .overlay_title-author_img-1 {
    height: 16px !important;
    width: 16px !important;
    margin-bottom: 0 !important;
  }

  .overlay_title {
    padding-top: 20px !important;
    padding-left: 16px !important;
    border-radius: 20px !important;
  }

  .video-category .video-category-head img.overlay_title-author_img {
    width: 60px !important;
    height: 60px !important;
    margin-bottom: 0 !important;
  }

  .video-category .video-category-item {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .video-category .video-category-item img {
    width: 100% !important;
  }

  .video-category .video-category-item .video-category-item_content-img-verify {
    width: 16px !important;
    height: 16px !important;
  }

  .video-detail_content-view {
    margin-top: 8px !important;
  }

  .related_video_thumbnail {
    border-radius: 10px 10px 0 0 !important;
  }

  .related_video_content {
    background-color: #252836 !important;
    padding: 20px 48px 24px 16px !important;
    border-radius: 0 0 10px 10px !important;
  }

  .related_video_author {
    margin-top: 8px !important;
  }

  .related_video_view {
    margin-top: 8px !important;
  }

  .container-video-detail {
    margin-top: 16px !important;
    padding: 16px !important;
    margin-top: 80px !important;
  }

  .container-video-detail_title {
    display: none !important;
  }

  .container-video-detail_subtitle {
    display: none !important;
  }

  .about-us .about-us-banner img {
    width: 100% !important;
    height: 184px !important;
  }

  .about-us .about-us-banner .about-us-content {
    width: 100% !important;
    background-color: #252836 !important;
  }

  .container-podcast .ant-tabs-content {
    margin-top: 16px !important;
  }

  .container-podcast .podcast-category-img {
    width: 100% !important;
    max-width: none !important;
  }

  /* .container-podcast-detail {
    margin-top: 16px !important;
  } */

  .learn-detail_category-related {
    display: none !important;
  }

  .container-game {
    padding: 16px 24px 48px !important;
    margin-top: 80px !important;
  }

  .form-login-img {
    padding: 0 !important;
    margin-bottom: 24px !important;
  }

  .form-login-img img {
    width: 100% !important;
    max-height: 280px !important;
    object-fit: cover !important;
    border-radius: 16px !important;
    object-position: bottom !important;
  }

  .form-login_sign_content {
    padding: 12px 16px !important;
    margin-bottom: 64px !important;
  }

  .life-tip-detail-content {
    padding: 16px !important;
    background-color: #1f1d2b !important;
  }

  .life-tip-detail-content_info {
    padding-bottom: 16px !important;
  }

  .life-tip-content .ant-col-sm-24,
  .life-tip-home_relate,
  .podcast-home-gutter {
    padding: 0 !important;
  }

  .hot-video-item-img_thumbnail {
    opacity: 0.7 !important;
  }

  .podcast-category-item_content {
    width: 40% !important;
  }

  .podcast-banner-img {
    width: 100% !important;
    max-width: none !important;
  }

  .podcast-banner-img img {
    display: block !important;
  }

  .hot-video-home_relate {
    padding: 0 !important;
  }

  .video-detail_content-action {
    justify-content: start !important;
  }
}

@media (max-width: 576px) {
  .footer-custom_logo {
    flex: 0 0 100% !important;
  }

  .form-login-img {
    padding: 0 !important;
    margin-bottom: 24px !important;
  }

  .form-login-img img {
    width: 100% !important;
    max-height: 216px !important;
    object-fit: cover !important;
    border-radius: 16px !important;
    object-position: bottom !important;
  }

  .form-login_sign_content {
    padding: 12px 16px !important;
    margin-bottom: 64px !important;
  }

  .banner-img {
    border-radius: 20px !important;
    width: 100% !important;
    height: 320px !important;

    object-fit: cover;
    object-position: left;
  }

  .life-tip-content,
  .life-tip-content .ant-col-xs-24 {
    padding: 0 !important;
  }

  .hot-video-item-img {
    padding: 0 !important;
  }

  .hot-video-item-img_thumbnail {
    border-radius: 10px 10px 0 0 !important;
  }

  .hot-video-home_relate {
    padding: 0 !important;
  }

  .hot-video-item_content {
    background-color: #242730 !important;
    padding: 20px 14px 24px !important;
    border-radius: 0 0 10px 10px !important;
  }

  .podcast-category-item_content {
    width: 100% !important;
  }

  .video-category-item_content_title {
    height: auto !important;
  }

  .video-category-head_content img {
    border-radius: 20px 20px 0 0;
  }

  .container-video-detail {
    padding: 16px 0 !important;
  }

  .container-life-tip-detail_title_mobile {
    padding: 16px 8px !important;
  }

  .video-detail_content-title,
  .video-detail_content-view_item,
  .video-detail_content-action,
  .video-detail_content-description,
  .video-detail_cmt_total,
  .video-detail_cmt-input,
  .video-detail_cmt-item {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .video-detail_content-view_action_first {
    padding-left: 8px !important;
  }

  .background-image-container {
    margin-top: 24px !important;
    height: 50vh !important;
    margin-bottom: 48px !important;
  }

  .video-category-head_content .overlay_title .overlay_title-txt {

    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
  }

  .overlay-next_video-container .overlay-next_video {
    max-width: 100% !important;
  }

  .overlay-next_video-container .overlay-next_video .overlay-next_video-info {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }

  .overlay-next_video-container .overlay-next_video .overlay-next_video-info .overlay-next_video-info_img {
    margin-right: 24px !important;
  }

  .overlay-next_video-container .overlay-next_video .overlay-next_video-info .overlay-next_video-info_img img {
    max-width: 184px !important;
  }

  .video-category-head_content .overlay_title .overlay_subtitle-txt {
    -webkit-line-clamp: 2 !important;
  }

}

@media (min-width: 860px) {
  .layout-admin-container {
    margin-left: 220px !important;
    /* background-color: #f4f6fb !important; */
  }
}